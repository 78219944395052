import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FaTelegram } from "react-icons/fa";



export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Follow Us</h2>
      <div className="cs_social_links">
        <Link to="https://www.facebook.com/people/Dr-Seid-Arage/100063831044055/">
          <Icon icon="fa-brands:facebook-f" />
        </Link>

        <Link to="https://t.me/drseidarage">
        <FaTelegram />
        </Link>

        <Link to="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiHwenG3rf7AhWAgP0HHWTXD4UQFnoECA8QAQ&url=https://www.youtube.com/channel/UClzK7cjhdzPT9MoJ1ueHNuQ&usg=AOvVaw1nLNbttrPHIJ3ju45VROIz">
          <Icon icon="fa-brands:youtube" />
        </Link>
      </div>
    </div>
  );
}
