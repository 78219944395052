import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
//import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Expanding access to Care',
    subTitle:
      'increasing the number of women who can access high quality gynecological & obstetrical services, regardless of their Socio-economic status.',
  },
  {
    iconSrc: '/images/home_1/patient_satisfaction.svg',
    title: 'Patient Satisfaction',
    subTitle:
      'Continuously improving patient  satisfaction by addressing concerns, and providing exceptional care.',
  },
  {
    iconSrc: '/images/home_1/patientcare.svg',
    title: 'Patients Centered Care',
    subTitle: `We prioritize the needs and well-beings of our patients above all else.`,
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'We set high standards & brings exceptional quality to all areas of women’s wealth care.',
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Respect',
    subTitle:
      'We value & appreciate  the uniqe experiences, background & needs of each Patients.',
  },
];
/* const brandData = [
  { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
]; */
const faqData = [
  {
    title: 'Can a pregnant mother fast during Ramadan?',
    content:
      'First and foremost, a mother is required to consume the best and most nutritious foods and liquids during pregnancy and breastfeeding. She requires different nutrition compared to others her age. Additionally, especially during the first 3 months (due to frequent nausea and vomiting) and in the last month, she may need to eat more than 5 times in 24 hours because her appetite decreases.',
  },
  {
    title: 'When traveling by air during pregnancy,',
    content:
      "it is essential to consult with your attending physician before booking an airline ticket and understand the condition of the fetus. If you need to travel by air during pregnancy and your doctor hasn't identified any concerns, it's strongly recommended to stand up and move around at least once every hour during the flight, especially on longer trips.",
  },
  {
    title: 'Vitamin D for children?',
    content:
      'Breastfeeding is strongly recommended for newborns, especially during the first six months. However, breast milk alone may not provide sufficient vitamin D for breastfed infants.',
  },
  {
    title: 'Is it safe to exercise during pregnancy?',
    content:
      "Yes, in most cases, it is safe and beneficial to exercise during pregnancy. However, it's important to consult with your healthcare provider before starting any exercise regimen. They can provide personalized recommendations based on your health status and pregnancy.",
  },
  {
    title: "What foods should I avoid during pregnancy?",
    content:
      "During pregnancy, it's recommended to avoid certain foods that may pose a risk to the health of you and your baby. These include unpasteurized dairy products, raw or undercooked meat and fish, deli meats and hot dogs unless heated to steaming hot, and certain types of fish high in mercury. It's also advisable to limit caffeine intake and avoid alcohol altogether.",
  },
];
/* const blogData = [
  {
    title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
    thumbUrl: 'images/home_1/post_1.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Healthy Eating on a Budget: Tips and Strategies',
    thumbUrl: 'images/home_1/post_2.jpeg',
    date: 'May 4, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'The Importance of Regular Cancer Screenings and Early Detection',
    thumbUrl: 'images/home_1/post_3.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
]; */
const awardData = [
  {
    title: 'Ibn Sina Charitable Organization',
    subTitle:
      'We present this certificate to you with great gratitude for your contribution to the emergency medical service organized at Addis Ababa Stadium on Eid al-Fitr in 1444 AH, trusting that your continued support will not leave us.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Certificate of appreciation',
    subTitle:
      'A certificate of appreciation from the public service provided by the district 6 health center of the city.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Office of Kolfe Karanyo sub-city',
    subTitle:
      'Kolfe Karanyo sub-city, important event registration and information branch office, we present this certificate with great respect for your outstanding participation in the current budget registration in the 2014 fiscal year.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Joint Commission Gold Seal of Approval',
    subTitle:
      'This recognition is given to hospitals that have met rigorous standards for patient safety and quality of care.',
    iconUrl: 'images/icons/award.svg',
  },
];

const departmentData = [
  {
    title: 'Preconceptual Care Department',
    iconUrl: 'images/home_1/Preconceptual.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Antenatal Care Departement',
    iconUrl: 'images/home_1/antenatal.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Ultrasound Monitoring Department',
    iconUrl: 'images/home_1/ultrasound.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Family Planning Department',
    iconUrl: 'images/home_1/family_planning.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Cervical Cancer Screening Department',
    iconUrl: 'images/home_1/cervical.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Child Care Department',
    iconUrl: 'images/home_1/childcare.svg',
    href: '/departments/department-details',
  }
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title="We Treat You As We Want To Be Treated"
        subTitle="We are committed to providing you with the best medical and healthcare services to help you live healthier and happier."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/O5GPqwjd9k0?si=rw5FN7FD906mekoT"
        infoList={[
          {
            title: 'Hotline',
            subTitle: 'Call: 0910161920',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Ambulance',
            subTitle: '9837',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'Location',
            subTitle: 'Betel, A.A',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Book Now"
        btnUrl="/appointments"
      />

      <Section id='feature'
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Values & Goals" data={featureListData} />
      </Section>
      
      <Section id='about'>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="Berekah Specialty Center"
          featureList={[
            {
              featureListTitle:
                'Berekah Specialty Center is a team of experienced medical professionals',
              featureListSubTitle:
                'Berekah Obstetric Gynecology Specialty Clinic was established 4 years ago with the aim of providing caring and respectful healthcare services to women of all ages, focusing on reproductive health, pregnancy care, and overall well-being. We achieve this by offering preventive care, education, management of chronic prenatal and postnatal care, and support.',
            },
          ]}
        />
      </Section>
      
      <Section id='departments' topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      <Section id='award' topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Departments" data={awardData} />
      </Section>

      <Section id='testimonial'
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      
      <Section id='banner'>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section>
      
      {/*    
      <Section id='blog topMd={190} topLg={145} topXl={105}>
              <BlogSection
                sectionTitle="Latest Update"
                sectionTitleUp="BLOG POSTS"
                data={blogData}
              />
      </Section> 
      */}

      <Section id="appointment" topMd={190} topLg={145} topXl={105}>
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpg"
        />
      </Section>
      
      <Section id='FAQ' topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>

    {/*   
    <Section id='brand
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section> 
      */}
    </>
  );
}
