import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection() {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">Find Us Here</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Phone"
            subTitle="9837"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="info@berekahspecialtycenter.com"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Location"
            subTitle="Betel, Addis Ababa, Ethiopia"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15762.61158854314!2d38.68602!3d9.004051!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b87084e1c2b6f%3A0x80430469c107f52e!2zQmVyZWthaCBTcGVjaWFsaXR5IENsaW5pYyB8IEJldGhlbCB8IOGJoOGIqOGKqyDhiI3hi6kg4Yqt4YiK4YqS4YqtIHwg4Ymk4Ymw4YiN!5e0!3m2!1sen!2sus!4v1708122983237!5m2!1sen!2sus"
          allowFullScreen
        />
      </div>
      {/* End Google Map */}
    </div>
  );
}
